import React, { useRef, useEffect, useState } from 'react';
import './MobileAgeSelector.css';

const MobileAgeSelector = ({ initialAge = 1, onAgeChange }) => {
  const [age, setAge] = useState(initialAge);
  const selectorRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const startScrollLeft = useRef(0);
  const itemWidth = 50;

  useEffect(() => {
    const selector = selectorRef.current;
    if (selector) {
      const centerOffset = (selector.clientWidth - itemWidth) / 2;
      selector.scrollLeft = (initialAge - 1) * itemWidth - centerOffset + itemWidth * 3;
    }
  }, [initialAge]);

  const handleTouchStart = (e) => {
    isDragging.current = true;
    startX.current = e.touches[0].pageX - selectorRef.current.offsetLeft;
    startScrollLeft.current = selectorRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.touches[0].pageX - selectorRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5;
    selectorRef.current.scrollLeft = startScrollLeft.current - walk;

    const centerOffset = (selectorRef.current.clientWidth - itemWidth) / 2;
    const newAge = Math.round((selectorRef.current.scrollLeft + centerOffset - itemWidth * 3) / itemWidth) + 1;
    setAge(newAge);
    onAgeChange(newAge);
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
    const centerOffset = (selectorRef.current.clientWidth - itemWidth) / 2;
    const newAge = Math.round((selectorRef.current.scrollLeft + centerOffset - itemWidth * 3) / itemWidth) + 1;
    setAge(newAge);
    onAgeChange(newAge);
    selectorRef.current.scrollLeft = (newAge - 1) * itemWidth - centerOffset + itemWidth * 3;
  };

  return (
    <>
      <h3>Feel old? You have time. Drag the age counter:</h3>
      <div className="mobile-age-selector-container">
        <div className="mobile-age-selector" 
             ref={selectorRef}
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}
             style={{ userSelect: 'none' }}
        >
          {Array.from({ length: 3 }, (_, i) => <div key={`empty-start-${i}`} className="age-item empty"></div>)}
          {[...Array(100).keys()].map(i => (
            <div key={i} className={`age-item ${i + 1 === age ? 'selected' : ''}`}>
              {i + 1}
            </div>
          ))}
          {Array.from({ length: 3 }, (_, i) => <div key={`empty-end-${i}`} className="age-item empty"></div>)}
        </div>
        <div className="age-indicator"></div>
      </div>
    </>
  );
};

export default MobileAgeSelector;
