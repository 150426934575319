import React, { useRef, useEffect, useState, useCallback } from 'react';
import AllGraphicSVG from '../assets/full.svg';
import DotSVG from '../assets/dot.svg';

import './AgeSelector.css';

const AgeSelector = ({ width, height, padding, offset, onAgeChange }) => {
  const svgRef = useRef();
  const dotRef = useRef();
  const ageTextRef = useRef();
  const [age, setAge] = useState(1);

  const calculateXPosition = useCallback((age) => {
    return padding + offset + ((age - 1) / 94) * (width - 2 * padding - 2 * offset);
  }, [padding, width, offset]);

  const calculateAge = useCallback((x) => {
    return Math.round(((x - padding - offset) / (width - 2 * padding - 2 * offset)) * 94 + 1);
  }, [padding, width, offset]);

  const updateDotPosition = useCallback((x) => {
    const newAge = calculateAge(x);
    const boundedAge = Math.max(1, Math.min(100, newAge));
    const dotX = calculateXPosition(boundedAge) - padding/2;
    dotRef.current.setAttribute('x', dotX);
    ageTextRef.current.setAttribute('x', dotX + padding/2);
    ageTextRef.current.textContent = boundedAge;
    setAge(boundedAge);
    onAgeChange(boundedAge);
  }, [calculateAge, calculateXPosition, onAgeChange]);

  useEffect(() => {
    const svg = svgRef.current;

    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }

    const allGraphic = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    allGraphic.setAttributeNS('http://www.w3.org/1999/xlink', 'href', AllGraphicSVG);
    allGraphic.setAttribute('width', width - padding * 2);
    allGraphic.setAttribute('height', height - padding);
    allGraphic.setAttribute('x', padding);
    allGraphic.setAttribute('y', padding);
    svg.appendChild(allGraphic);

const dot = document.createElementNS('http://www.w3.org/2000/svg', 'image');
dot.setAttributeNS('http://www.w3.org/1999/xlink', 'href', DotSVG);
dot.setAttribute('width', padding);
dot.setAttribute('height', padding);
dot.setAttribute('x', calculateXPosition(age) - padding/2);
dot.setAttribute('y', padding/2 + 63/400*height);
dot.classList.add('draggable', 'pulsating');  // Add 'pulsating' class here
svg.appendChild(dot);
dotRef.current = dot;

    const ageText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    ageText.setAttribute('x', calculateXPosition(age));
    ageText.setAttribute('y', padding/2 + 57/400*height);
    ageText.setAttribute('text-anchor', 'middle');
    ageText.setAttribute('font-size', '18px');
    ageText.setAttribute('font-weight', 'bold');
    ageText.setAttribute('style', 'user-select: none');
    ageText.textContent = age;
    svg.appendChild(ageText);
    ageTextRef.current = ageText;
  }, [age, calculateXPosition, width, height, padding, offset]);

  useEffect(() => {
    const svg = svgRef.current;
    let selectedElement = null;
    let offsetPos = {};

    const getMousePosition = (evt) => {
      const CTM = svg.getScreenCTM();
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      };
    };

    const startDrag = (evt) => {
      if (evt.target.classList.contains('draggable')) {
        selectedElement = evt.target;
        offsetPos = getMousePosition(evt);
        offsetPos.x -= parseFloat(selectedElement.getAttribute('x'));
        offsetPos.y -= parseFloat(selectedElement.getAttribute('y'));
        document.querySelector('.graph-container').style.userSelect = 'none';
      }
    };

    const drag = (evt) => {
      if (selectedElement) {
        const coord = getMousePosition(evt);
        const x = coord.x - offsetPos.x;
        selectedElement.setAttribute('x', x);
        updateDotPosition(coord.x);
      }
    };

    const endDrag = () => {
      selectedElement = null;
      document.querySelector('.graph-container').style.userSelect = '';
    };

    svg.addEventListener('pointerdown', startDrag);
    window.addEventListener('pointermove', drag);
    window.addEventListener('pointerup', endDrag);
    window.addEventListener('pointercancel', endDrag);

    return () => {
      svg.removeEventListener('pointerdown', startDrag);
      window.removeEventListener('pointermove', drag);
      window.removeEventListener('pointerup', endDrag);
      window.removeEventListener('pointercancel', endDrag);
    };
  }, [updateDotPosition]);

  return (
    <svg ref={svgRef} width={width} height={height}></svg>
  );
};

export default AgeSelector;
