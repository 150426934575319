import React, { useState, useEffect } from 'react';
import achievementsData from '../data/achievement4.json';
import './Graph.css';
import AgeSelector from './AgeSelector';
import MobileAgeSelector from './MobileAgeSelector';
import FrameSVG from '../assets/frame1.svg';
import Man from '../assets/SVG/man.svg';
import Asset3 from '../assets/SVG/Asset 3.svg';
import Asset4 from '../assets/SVG/Asset 4.svg';

const Graph = () => {
  const [age, setAge] = useState(1);
  const [loadingImages, setLoadingImages] = useState([true, true, true]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const multiplier = 8;
  const width = 80 * multiplier;
  const height = 40 * multiplier;
  const padding = 3 * multiplier;
  const offset = 7 * multiplier;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const countFrames = isMobile ? 2 : 3;
  const currentAchievements = (achievementsData.inspirational_stories[age] || [])
    .filter(achievement => achievement.image_url)
    .slice(0, countFrames);

  const achievementsToDisplay = [...currentAchievements];
  while (achievementsToDisplay.length < countFrames) {
    achievementsToDisplay.push({ placeholder: true });
  }

  const handleImageLoad = (index) => {
    const newLoadingImages = [...loadingImages];
    newLoadingImages[index] = false;
    setLoadingImages(newLoadingImages);
  };

  const achievements = (
    <div className="achievements">
      {achievementsToDisplay.map((achievement, index) => (
        <div key={index} className="achievement">
          <img src={FrameSVG} alt="frame" className="frame" />
          {achievement.placeholder ? (
            <div className="frame-content">
              <h3>Empty</h3>
              <p>No achievement data available.</p>
            </div>
          ) : (
            <div className="frame-content">
              {loadingImages[index] && <div className="spinner"></div>}
              <img
                src={achievement.image_url}
                alt={achievement.name}
                onLoad={() => handleImageLoad(index)}
                style={{ display: loadingImages[index] ? 'none' : 'block' }}
              />
              <h3>{achievement.name}</h3>
              <p>{achievement.achievement}</p>
              {achievement.wiki_url && (
                <a href={achievement.wiki_url} target="_blank" rel="noopener noreferrer">
                  Wiki page
                </a>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="graph-container">
      <div className="footer-title">It's Not Too Late</div>
      {isMobile ? (
        <>
          <MobileAgeSelector age={age} onAgeChange={setAge} />
          <p>They "made it" only at the age {age}.</p>
          <div className="mobile-achievements-container">
            <img src={Asset3} className="side-asset left-asset" alt="Left Asset" />
            {achievements}
            <img src={Asset4} className="side-asset right-asset" alt="Right Asset" />
          </div>
        </>
      ) : (
        <>
          <p>Drag the age counter right or left:</p>
          <div className='age_selector_and_image'>
            <img src={Man} className="should_be_on_the_left" />
            <div className='should_be_in_the_center'>
              <AgeSelector
                width={width}
                height={height}
                padding={padding}
                offset={offset}
                onAgeChange={setAge}
              />
            </div>
          </div>
          {achievements}
        </>
      )}
      <footer className="footer">
        <p>It's not too late. 1000+ examples pulled and visualised by <a href="https://www.instagram.com/alfie.avga" target="_blank" rel="noopener noreferrer">@alfie.avga</a></p>
      </footer>
    </div>
  );
};

export default Graph;
